import React from 'react'
import {MainContainer} from './ui/command-center-layout'
import {Button, Header, Icon} from '@thryvlabs/maverick'
import SkeletonLoader from './skeleton-loader'
import {useFlags} from 'launchdarkly-react-client-sdk'

function CommandCenterLayout({
  children,
  title,
  prevStep,
  centeredHeader,
  columnAmount,
  isLoading,
  showBackButton = false,
  setShowUpdateAddressModal,
}) {
  const {frontendCatalogBackButton} = useFlags()
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <SkeletonLoader height="50vh" width="79vw" />
      </div>
    )
  }
  return (
    <MainContainer columnAmount={columnAmount}>
      <div>
        {!frontendCatalogBackButton ? (
          <Icon
            type="solid"
            variant="arrowLeft"
            className="back__arrow mb-2"
            onClick={prevStep}
          />
        ) : (
          <Button
            variant="secondary"
            onClick={() => {
              setShowUpdateAddressModal(true)
            }}
          >
            Update Account
          </Button>
        )}
      </div>
      {title && (
        <div className={centeredHeader && 'centered-header'}>
          <Header fontWeight="normal" variant="h1">
            {title}
          </Header>
        </div>
      )}
      {children}
    </MainContainer>
  )
}

export {CommandCenterLayout}
