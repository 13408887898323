import React, {useState, useEffect, useCallback} from 'react'
import {
  Modal,
  ModalTitle,
  Input,
  Select,
  ParagraphText,
  RadioGroup,
  // Button,
  Icon,
  Checkbox,
} from '@thryvlabs/maverick'
import {useForm, Controller} from 'react-hook-form'
import rawCountryStates from 'iso3166-2-db/i18n/en'
import * as yup from 'yup'
import validator from 'validator'
import {PhoneNumberUtil} from 'google-libphonenumber'
import {yupResolver} from '@hookform/resolvers/yup'
import {useAuthClient} from '../utils/use-auth-client'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {UpdateAddressModalContainer} from './ui/update-address-modal'
import {useUserRoles} from '../utils/use-user-roles'
import {toast} from 'react-toastify'
import AsyncSelect from 'react-select/async'

const phoneUtil = PhoneNumberUtil.getInstance()
const REQUIRED_FIELD_MESSAGE = 'This field is required'
const MIN_CHARACTERS_REQUIRED = 'Must be at least 3 characters'
const MAX_CHARACTERS_REQUIRED = 'Must be at most 120 characters'
const REQUIRED_OPTION_MESSAGE = 'Please select an option'
const MIN_NUMBERS_REQUIRED = 'Must be 10-digit numbers.'
const MAX_NUMBERS_REQUIRED = 'Max of 10-digit numbers.'
const SALES_REP_INFO_NOT_FOUND = 'Sales rep not Found.'
const SST_SALES_REP_INFO_NOT_FOUND = 'Sales rep not Found.'

// const DIFFERENT_SALES_REP_OPTIONS = [
//   {name: 'Sales Rep', value: true},
//   {name: 'Partner', value: false},
//   {name: 'No', value: 'No'},
// ]
// let DIFFERENT_SALES_REP_OPTIONS = []
const DIFFERENT_SALES_REP_OPTIONS_USER_SPECIFIC = [
  {name: 'Sales Rep', value: true},
  {name: 'No', value: 'NO'},
]

let DIFFERENT_SALES_REP_OPTIONS = [
  {name: 'Sales Rep', value: true},
  {name: 'Partner', value: false},
  {name: 'No', value: 'NO'},
]

const OVERRIDE_SST_OPTIONS = [
  {name: 'Yes', value: true},
  {name: 'No', value: false},
]

const formatPostalCode = (postCode, country) => {
  if (country === 'CA' && postCode) {
    return postCode.slice(0, 3) + ' ' + postCode.slice(3)
  }
  return postCode
}

const formatNumber = number => number?.replace(/-/g, '')

const SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  lastName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  company: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  zipCode: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'validate-postCode-format',
      'Incorrect post code format',
      function (value) {
        if (!value) {
          return false
        }
        const country = this.options.context.componentProps.selectedCountry
        if (country === 'BB') {
          return value.match(/^[A-Za-z]{2}\d{5}$/)
        }
        if (country === 'KY') {
          return value.match(/^[A-Za-z]{2}\d-?\d{4}$/)
        }
        if (country === 'CA') {
          return value.match(/^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/)
        }
        return validator.isPostalCode(value, country)
      },
    ),
  streetAddress: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  city: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  state: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  phone: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(10, MIN_NUMBERS_REQUIRED)
    .max(14, MAX_NUMBERS_REQUIRED)
    .test(
      'validate-phone-format',
      'Incorrect phone number format',
      function (value) {
        if (!value) {
          return false
        }
        const country = this.options.context.componentProps.selectedCountry
        const parsedPhone = phoneUtil.parse(value, country)
        let validationResult = phoneUtil.isPossibleNumberWithReason(parsedPhone)
        return validationResult === PhoneNumberUtil.ValidationResult.IS_POSSIBLE
      },
    ),
  mobile: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(10, MIN_NUMBERS_REQUIRED)
    .max(14, MAX_NUMBERS_REQUIRED)
    .test(
      'validate-phone-format',
      'Incorrect phone number format',
      function (value) {
        if (!value) {
          return false
        }
        const country = this.options.context.componentProps.selectedCountry
        const parsedPhone = phoneUtil.parse(value, country)
        let validationResult = phoneUtil.isPossibleNumberWithReason(parsedPhone)
        return validationResult === PhoneNumberUtil.ValidationResult.IS_POSSIBLE
      },
    ),
  salesRepInfo: yup.string().when('differentSalesRep', {
    is: differentSalesRep =>
      differentSalesRep && differentSalesRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  overrideRepInfo: yup.string().when('overrideRep', {
    is: overrideRep => overrideRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  salesRepCode: yup.string().when('differentSalesRep', {
    is: differentSalesRep =>
      differentSalesRep && differentSalesRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  overrideRepCode: yup.string().when('overrideRep', {
    is: overrideRep => overrideRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  xpCode: yup.string(),
  // keapAccount: yup.boolean().optional(),
  abn: yup.string().test('validate-abn', function (value) {
    const lowerCountry =
      this.options.context.componentProps.selectedCountry.toLowerCase()
    const {is5Order} = this.options.context.componentProps
    if (!is5Order) return true
    if (lowerCountry === 'au' && (!value || value.length !== 11)) {
      return this.createError({message: 'Please enter a valid, 11-digit ABN'})
    }
    if (lowerCountry === 'nz') {
      if (!value) return true
      if (value && value.length !== 13) {
        return this.createError({
          message: 'Please enter a valid, 13-digit NZBN',
        })
      }
    }
    return true
  }),
})

const UpdateAddressModal = ({
  nextStep,
  selectedCountry,
  accountInfo,
  disableUpdateAddressModal,
  disableOpenAddressModal,
  is5Order,
  setIsAddressUpdated,
  openOnRender,
  updateAddressInfo,
  setShowUpdateAddressModal,
  order,
  hasKeapAccountAbility,
  setHasKeapAccountAbility,
  keapAccount = false,
  setKeapAccount,
  handleStartOver,
}) => {
  const [selectedSalesRep, setSelectedSalesRep] = useState(null)
  const [selectedOverrideRep, setSelectedOverridRep] = useState(null)
  const {frontendOverrideSst} = useFlags()
  const {roles} = useUserRoles()
  console.log({
    accountInfo,
    keapAccount,
    hasKeapAccountAbility,
  })
  // if (
  //   roles.includes('PremisePilot') ||
  //   roles.includes('LeadConverter') ||
  //   roles.includes('Reseller') ||
  //   roles.includes('Admin')
  // ) {
  //   DIFFERENT_SALES_REP_OPTIONS = [
  //     {name: 'Sales Rep', value: true},
  //     {name: 'Partner', value: false},
  //     {name: 'No', value: 'No'},
  //   ]
  // } else {
  //   DIFFERENT_SALES_REP_OPTIONS = [
  //     {name: 'Sales Rep', value: true},
  //     {name: 'No', value: 'No'},
  //   ]
  // }

  const getSalesRepValues = () => {
    const roleAuth =
      roles.includes('PremisePilot') ||
      roles.includes('LeadConverter') ||
      roles.includes('Reseller') ||
      roles.includes('Admin')
    if (roleAuth) {
      return accountInfo &&
        Object.prototype.hasOwnProperty.call(accountInfo, 'salesRepCode') &&
        accountInfo.salesRepCode
        ? DIFFERENT_SALES_REP_OPTIONS[0]
        : accountInfo &&
          Object.prototype.hasOwnProperty.call(accountInfo, 'xpCode') &&
          accountInfo.xpCode
        ? DIFFERENT_SALES_REP_OPTIONS[1]
        : DIFFERENT_SALES_REP_OPTIONS[2]
    } else {
      return accountInfo &&
        Object.prototype.hasOwnProperty.call(accountInfo, 'salesRepCode') &&
        accountInfo.salesRepCode
        ? DIFFERENT_SALES_REP_OPTIONS_USER_SPECIFIC[0]
        : DIFFERENT_SALES_REP_OPTIONS_USER_SPECIFIC[1]
    }
  }

  const differentSalesRepValue = getSalesRepValues()

  const [selectedState, setSelectedState] = useState(
    () =>
      rawCountryStates[selectedCountry]?.regions
        .map(({iso, name}) => ({
          name,
          value: iso || name,
        }))
        .find(
          ({value}) =>
            value ===
            (accountInfo?.billingAddress?.region || accountInfo?.state),
        ) || {
        name: '',
        value: '',
      },
  )
  const [resetSelectedState, setResetSelectedState] = useState(false)
  const [listOfStates, setListOfStates] = useState([])
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const client = useAuthClient()
  const {billingAddress, recurlyPhone} = accountInfo
  const {
    register,
    handleSubmit,
    trigger,
    control,
    setValue,
    watch,
    formState: {errors},
    reset,
  } = useForm({
    defaultValues: {
      company: accountInfo?.recurlyCompany || accountInfo?.company || '',
      firstName: accountInfo?.recurlyFirst || accountInfo?.firstName || '',
      lastName: accountInfo?.recurlyLast || accountInfo?.lastName || '',
      streetAddress:
        accountInfo?.billingAddress?.street1 || accountInfo?.address || '',
      city: accountInfo?.billingAddress?.city || accountInfo?.city || '',
      state: rawCountryStates[selectedCountry]?.regions
        .map(({iso, name}) => ({
          name,
          value: iso || name,
        }))
        .find(
          ({value}) =>
            value ===
            (accountInfo?.billingAddress?.region || accountInfo?.state),
        ) || {
        name: '',
        value: '',
      },
      zipCode: formatPostalCode(
        accountInfo?.billingAddress?.postalCode || accountInfo?.postCode || '',
        selectedCountry,
      ),
      phone:
        formatNumber(
          billingAddress?.phone || recurlyPhone || accountInfo?.companyPhone,
        ) || '',
      mobile:
        formatNumber(
          billingAddress?.mobile || recurlyPhone || billingAddress?.phone,
        ) || '',
      differentSalesRep: accountInfo?.salesRepCode
        ? DIFFERENT_SALES_REP_OPTIONS[0]
        : accountInfo?.xpCode
        ? DIFFERENT_SALES_REP_OPTIONS[1]
        : DIFFERENT_SALES_REP_OPTIONS[2],
      overrideRep: accountInfo?.overrideRepCode
        ? OVERRIDE_SST_OPTIONS[0]
        : OVERRIDE_SST_OPTIONS[1],
      abn: accountInfo?.abn || '',
      salesRepCode: accountInfo?.salesRepCode || '',
      salesRepInfo: accountInfo?.salesRepInfo || '',
      overrideRepInfo: accountInfo?.overrideRepInfo || '',
      overrideRepCode: accountInfo?.overrideRepCode || '',
      xpCode: accountInfo?.xpCode || '',
      keapAccount: keapAccount || null,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(SCHEMA),
    context: {componentProps: {selectedCountry, is5Order}},
  })

  useEffect(() => {
    if (accountInfo) {
      reset({
        company: accountInfo?.recurlyCompany || accountInfo?.company || '',
        firstName: accountInfo?.recurlyFirst || accountInfo?.firstName || '',
        lastName: accountInfo?.recurlyLast || accountInfo?.lastName || '',
        streetAddress:
          accountInfo?.billingAddress?.street1 || accountInfo?.address || '',
        city: accountInfo?.billingAddress?.city || accountInfo?.city || '',
        state: rawCountryStates[selectedCountry]?.regions
          .map(({iso, name}) => ({
            name,
            value: iso || name,
          }))
          .find(
            ({value}) =>
              value ===
              (accountInfo?.billingAddress?.region || accountInfo?.state),
          ) || {
          name: '',
          value: '',
        },
        zipCode: formatPostalCode(
          accountInfo?.billingAddress?.postalCode ||
            accountInfo?.postCode ||
            '',
          selectedCountry,
        ),
        phone:
          formatNumber(
            billingAddress?.phone || recurlyPhone || accountInfo?.companyPhone,
          ) || '',
        mobile:
          formatNumber(
            billingAddress?.mobile || recurlyPhone || billingAddress?.phone,
          ) || '',
        differentSalesRep: accountInfo?.salesRepCode
          ? DIFFERENT_SALES_REP_OPTIONS[0]
          : accountInfo?.xpCode
          ? DIFFERENT_SALES_REP_OPTIONS[1]
          : DIFFERENT_SALES_REP_OPTIONS[2],
        overrideRep: accountInfo?.overrideRepCode
          ? OVERRIDE_SST_OPTIONS[0]
          : OVERRIDE_SST_OPTIONS[1],
        abn: accountInfo?.abn || '',
        xpCode: accountInfo?.xpCode || '',
        salesRepCode: accountInfo?.salesRepCode || '',
        salesRepInfo: accountInfo?.salesRepInfo || '',
        overrideRepCode: accountInfo?.overrideRepCode || '',
        overrideRepInfo: accountInfo?.overrideRepInfo || '',
        keapAccount: keapAccount || null,
      })
      setSelectedSalesRep({
        label: accountInfo.salesRepInfo,
        value: accountInfo.salesRepInfo,
      })
      setSelectedOverridRep({
        label: accountInfo.overrideRepInfo,
        value: accountInfo.overrideRepInfo,
      })
      trigger()
    }
  }, [
    accountInfo,
    selectedCountry,
    billingAddress,
    recurlyPhone,
    reset,
    trigger,
  ])

  const [
    differentSalesRep,
    salesRepCode,
    salesRepInfo,
    overrideRep,
    overrideRepInfo,
    overrideRepCode,
  ] = watch([
    'differentSalesRep',
    'salesRepCode',
    'salesRepInfo',
    'overrideRep',
    'overrideRepInfo',
    'overrideRepCode',
  ])

  console.log(salesRepCode)
  const isAu = selectedCountry === 'AU'

  const setState = (value, reset) => {
    setSelectedState(value)
    setValue('state', {...value})
    if (reset) {
      setResetSelectedState(true)
    } else {
      trigger('prospectsState')
    }
  }

  const getStates = useCallback(() => {
    const states = rawCountryStates[selectedCountry].regions
      .map(({iso, name}) => ({
        name,
        value: iso || name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1))
    setListOfStates(states)
  }, [selectedCountry])

  const fetchNameOptions = async (inputValue, country) => {
    let sftoken = sessionStorage.getItem('sfToken') || ''
    if (!country) {
      country = 'US'
    }
    if (sftoken.length > 0) {
      sftoken = JSON.parse(sftoken)
      sftoken = country === 'AU' ? sftoken.ausToken : sftoken.nonAusToken
    }
    const data = await client(
      `salesforce/services/fetchUsers?token=${sftoken}&searchTerm=${inputValue}&country=${country}`,
    )
    const filterData = data.data.filter(item =>
      item.Name.toLowerCase().includes(inputValue.toLowerCase()),
    )
    return filterData.map(item => ({
      label: item.Name,
      value: item.Name,
      salesRepInfo: item,
    }))
  }

  const loadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length >= 3) {
      fetchNameOptions(inputValue, selectedCountry).then(options => {
        callback(options)
      })
    } else if (!inputValue) {
      callback([])
    }
  }
  const handleSalesRepOptionChange = selectedOption => {
    setSelectedSalesRep(selectedOption)
    const salesRepInfo = selectedOption.salesRepInfo
    try {
      setValue('salesRepCode', salesRepInfo.FederationIdentifier)

      setValue('salesRepInfo', `${salesRepInfo.Name}`)
      setValue('behalfFirst', salesRepInfo.FirstName)
      setValue('behalfLast', salesRepInfo.LastName)
      setValue('behalfEmail', salesRepInfo.Email)
    } catch (error) {
      if (error.status === 404) {
        setValue('salesRepInfo', SALES_REP_INFO_NOT_FOUND)
      }
    }
    trigger('salesRepInfo')
  }

  const handleOverrideRepOptionChange = selectedOption => {
    setSelectedOverridRep(selectedOption)
    const overrideRepInfo = selectedOption.salesRepInfo
    try {
      setValue('overrideRepInfo', `${overrideRepInfo.Name}`)
      setValue('overrideRepCode', overrideRepInfo.FederationIdentifier)
      setValue('overrideRepFirst', overrideRepInfo.FirstName)
      setValue('overrideRepLast', overrideRepInfo.LastName)
      setValue('overrideRepEmail', overrideRepInfo.Email)
    } catch (error) {
      if (error.status === 404) {
        setValue('overrideRepInfo', SST_SALES_REP_INFO_NOT_FOUND)
      }
    }
    trigger('overrideRepInfo')
  }

  const resetOnBehalf = async () => {
    setValue('salesRepInfo', '')
    setValue('salesRepCode', '')
    // setSelectedSalesRep(null)
    setSelectedSalesRep({label: '', name: ''})
  }

  const resetSstOnBehalf = async () => {
    setValue('overrideRepInfo', '')
    setValue('overrideRepCode', '')
    // setSelectedOverridRep('')
    setSelectedOverridRep({label: '', name: ''})
  }

  const handleContinue = formData => {
    let data = {
      city: formData.city,
      region: formData.state.value,
      postalCode: formData.zipCode.replace(' ', ''),
      street1: formData.streetAddress,
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: formData.phone,
      company: formData.company,
      mobile: formData.mobile,
      differentSalesRep: formData.differentSalesRep,
      salesRepCode: formData.salesRepCode,
      salesRepInfo: formData.salesRepInfo,
      behalfFirst: formData.behalfFirst,
      behalfLast: formData.behalfLast,
      behalfEmail: formData.behalfEmail,
      overrideRepInfo: formData.overrideRepInfo,
      overrideRepCode: formData.overrideRepCode,
      overrideRepEmail: formData.overrideRepEmail,
      overrideRepFirst: formData.overrideRepFirst,
      overrideRepLast: formData.overrideRepLast,
      xpCode: formData.xpCode,
      keapAccount: keapAccount,
    }

    if (differentSalesRep && differentSalesRep.value === 'NO') {
      delete data.salesRepInfo
      delete data.salesRepCode
      delete data.behalfEmail
      delete data.behalfFirst
      delete data.behalfLast
      delete data.xpCode
    }

    if (differentSalesRep && differentSalesRep.value === true) {
      delete data.xpCode
    }

    if (differentSalesRep && differentSalesRep.value === false) {
      delete data.salesRepInfo
      delete data.salesRepCode
      delete data.behalfEmail
      delete data.behalfFirst
      delete data.behalfLast
    }

    if (!overrideRep.value || !overrideRepCode) {
      delete data.overrideRepInfo
      delete data.overrideRepCode
      delete data.overrideRepEmail
      delete data.overrideRepFirst
      delete data.overrideRepLast
    }
    console.log('keap account related trigger', {data})

    if (is5Order && ['AU', 'NZ'].includes(selectedCountry)) {
      data.abn = formData.abn
    }
    console.log(openOnRender, is5Order)
    if (is5Order && !openOnRender) {
      setIsAddressUpdated(true)
    }

    if (openOnRender) {
      updateAddressInfo(data, setShowUpdateAddressModal)
      toast.success('Address updated')

      setShowUpdateAddressModal(false)
      if (hasKeapAccountAbility) {
        handleStartOver()
      }
    } else {
      nextStep(data)
    }
  }

  // useEffect(() => {
  //   // if (accountInfo?.legacyAddOns?.length) {
  //   //   setKeapAccessibilityStatus(false)
  //   // } else {
  //   //   if (accountInfo?.addOns?.length) {
  //   //     setKeapAccessibilityStatus(false)
  //   //   } else {
  //   //     setKeapAccessibilityStatus(true)
  //   //   }
  //   // }
  // }, [])

  useEffect(() => {
    getStates()
  }, [getStates])

  useEffect(() => {
    if (
      (differentSalesRep && differentSalesRep.value && overrideRep.value) ===
        true ||
      (differentSalesRep &&
        differentSalesRep.value &&
        overrideRep.value === false) ||
      (differentSalesRep &&
        differentSalesRep.value === false &&
        overrideRep.value)
    ) {
      setIsSaveButtonDisabled(true)
    } else {
      setIsSaveButtonDisabled(false)
    }

    if (differentSalesRep && differentSalesRep.value === 'NO') {
      setIsSaveButtonDisabled(false)
    }

    if (
      differentSalesRep &&
      differentSalesRep.value &&
      salesRepInfo &&
      salesRepInfo !== SALES_REP_INFO_NOT_FOUND &&
      overrideRep.value === false
    ) {
      setIsSaveButtonDisabled(false)
    }

    if (
      overrideRep.value &&
      overrideRepInfo &&
      overrideRepInfo !== SST_SALES_REP_INFO_NOT_FOUND &&
      differentSalesRep &&
      differentSalesRep.value === false
    ) {
      setIsSaveButtonDisabled(false)
    }

    if (
      differentSalesRep &&
      differentSalesRep.value &&
      salesRepInfo &&
      salesRepInfo !== SALES_REP_INFO_NOT_FOUND &&
      overrideRep.value &&
      overrideRepInfo &&
      overrideRepInfo !== SST_SALES_REP_INFO_NOT_FOUND
    ) {
      setIsSaveButtonDisabled(false)
    }
  }, [salesRepInfo, differentSalesRep, overrideRepInfo, overrideRep])

  return (
    <Modal
      btnText="Continue"
      btnType={openOnRender ? null : 'primary'}
      width="600px"
      action
      footer
      btnActionText="Save"
      primaryDisabled={disableUpdateAddressModal}
      btnActionDisabled={isSaveButtonDisabled}
      disableModal={disableOpenAddressModal}
      btnAction={handleSubmit(handleContinue)}
      onClose={
        openOnRender
          ? () => {
              setShowUpdateAddressModal(false)
            }
          : null
      }
      openOnLoad={openOnRender}
    >
      <div className="text-center">
        <ModalTitle variant="subtitle" size="md">
          Please update account information.
        </ModalTitle>
      </div>
      <UpdateAddressModalContainer>
        <div style={{gridColumn: '1 / 4'}}>
          <Input
            customPlaceholder="Company"
            type="text"
            variant="default"
            className="align-self-stretch w-100"
            aria-label="Company"
            name="company"
            register={register}
            errors={errors}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridColumn: '1 / 4',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '40px',
            rowGap: '30px',
          }}
        >
          <Input
            customPlaceholder="First Name"
            type="text"
            variant="default"
            className="align-self-stretch w-100"
            aria-label="First Name"
            name="firstName"
            register={register}
            errors={errors}
          />
          <Input
            customPlaceholder="Last Name"
            type="text"
            variant="default"
            className="align-self-stretch w-100"
            aria-label="Last Name"
            name="lastName"
            register={register}
            errors={errors}
          />
        </div>
        <div style={{gridColumn: '1 / 4'}}>
          <Input
            customPlaceholder="Street Address"
            type="text"
            variant="default"
            className="align-self-stretch w-100"
            aria-label="Street Address"
            name="streetAddress"
            register={register}
            errors={errors}
          />
        </div>
        <div style={{paddingTop: '7px'}}>
          <Input
            customPlaceholder="City"
            type="text"
            variant="default"
            className="align-self-stretch w-100"
            aria-label="City"
            name="city"
            register={register}
            errors={errors}
          />
        </div>
        {listOfStates.length && (
          <div style={{gridColumn: '2 / 3'}}>
            <Controller
              control={control}
              name="state"
              render={({field: {onChange, name}}) => (
                <Select
                  options={listOfStates}
                  width="full"
                  selectLabel="State"
                  selectedOption={selectedState}
                  setSelectedOption={setState}
                  errors={errors}
                  onChange={onChange}
                  name={name}
                  cleared={resetSelectedState}
                  setCleared={setResetSelectedState}
                  withLabel={selectedState.value}
                  className="align-self-stretch w-100"
                />
              )}
            />
          </div>
        )}
        <div style={{paddingTop: '7px'}}>
          <Input
            customPlaceholder="Zip Code"
            type="text"
            variant="default"
            className="align-self-stretch w-100"
            aria-label="Zip Code"
            name="zipCode"
            register={register}
            errors={errors}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridColumn: '1 / 4',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '40px',
            rowGap: '30px',
          }}
        >
          <div style={{position: 'relative', paddingTop: '11px'}}>
            <ParagraphText
              variant="sm"
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                paddingBottom: '12px',
                margin: 0,
              }}
              color="thryv-gray-medium-500"
            >
              Phone
            </ParagraphText>
            <Input
              customPlaceholder="Phone"
              type="text"
              variant="default"
              className="align-self-stretch w-100"
              aria-label="Phone"
              name="phone"
              register={register}
              errors={errors}
            />
          </div>
          <div style={{position: 'relative', paddingTop: '11px'}}>
            <ParagraphText
              variant="sm"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                paddingBottom: '12px',
                margin: 0,
              }}
              color="thryv-gray-medium-500"
            >
              Mobile
            </ParagraphText>
            <Input
              customPlaceholder="Mobile Number"
              type="text"
              variant="default"
              className="align-self-stretch w-100"
              aria-label="Mobile Number"
              name="mobile"
              register={register}
              errors={errors}
            />
          </div>
        </div>
        {is5Order && (
          <>
            <div className="input-container">
              {(hasKeapAccountAbility || keapAccount) &&
                selectedCountry === 'US' && (
                  <Checkbox
                    hasLabel
                    name="keapAccount"
                    label={
                      <label style={{color: '#36A635', margin: 0}}>
                        <b>Keap Account</b>
                      </label>
                    }
                    value={keapAccount}
                    isChecked={keapAccount}
                    disabled={!hasKeapAccountAbility}
                    onChange={() => {
                      setKeapAccount(prev => !prev)
                      toast.warn(
                        'Please be aware that updating the Keap Account checkbox will clear all items from your order, and you will need to re-add them. Thank you for your understanding.',
                      )
                    }}
                  />
                )}
            </div>
            {['AU', 'NZ'].includes(selectedCountry) && (
              <div
                style={{
                  display: 'grid',
                  gridColumn: '1 / 4',
                  gridTemplateColumns: '1fr',
                  columnGap: '40px',
                  rowGap: '30px',
                }}
              >
                <div className="input_container">
                  <Input
                    className="w-100 mb-2"
                    type="text"
                    placeholder={
                      isAu
                        ? 'Australian Business Number (ABN)'
                        : 'New Zealand Business Number (NZBN)'
                    }
                    customPlaceholder={isAu ? 'ABN' : 'NZBN'}
                    aria-label="Australian Business Numberbn"
                    name="abn"
                    withLabel
                    labelType="floating"
                    variant="default"
                    register={register}
                    errors={errors}
                  />
                  <a
                    href={
                      isAu
                        ? 'https://abr.business.gov.au/'
                        : 'https://www.nzbn.govt.nz/'
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="abn-link"
                  >
                    {isAu ? 'ABN' : 'NZBN'} Lookup
                  </a>
                </div>
              </div>
            )}
            <div
              style={{
                display: 'grid',
                gridColumn: '1 / 4',
                gridTemplateColumns: '1fr',
                columnGap: '40px',
                rowGap: '30px',
              }}
            >
              <Controller
                control={control}
                name="differentSalesRep"
                render={({field: {onChange, name}}) => (
                  <RadioGroup
                    // data={DIFFERENT_SALES_REP_OPTIONS}
                    data={
                      roles.includes('PremisePilot') ||
                      roles.includes('LeadConverter') ||
                      roles.includes('Reseller') ||
                      roles.includes('Admin')
                        ? DIFFERENT_SALES_REP_OPTIONS
                        : DIFFERENT_SALES_REP_OPTIONS_USER_SPECIFIC
                    }
                    value={differentSalesRepValue}
                    // value={
                    //   accountInfo?.salesRepCode
                    //     ? DIFFERENT_SALES_REP_OPTIONS[0]
                    //     : accountInfo?.xpCode
                    //     ? DIFFERENT_SALES_REP_OPTIONS[1]
                    //     : DIFFERENT_SALES_REP_OPTIONS[2]
                    // }
                    label="Is this sale on behalf of another sales rep or partner?"
                    row
                    layoutStyles={{
                      gap: '10px',
                    }}
                    onChange={onChange}
                    name={name}
                  />
                )}
              />
              <div className="d-flex" style={{marginTop: '-20px'}}>
                {differentSalesRep?.value === true && (
                  <>
                    <div
                      className="input_container mt-4"
                      style={{width: '300px'}}
                    >
                      <AsyncSelect
                        cacheOptions
                        placeholder="Search by Name"
                        loadOptions={loadOptions}
                        onChange={handleSalesRepOptionChange}
                        value={selectedSalesRep}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: 'silver',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                    {selectedSalesRep &&
                    selectedSalesRep.label &&
                    selectedSalesRep.label.length ? (
                      <>
                        <Icon
                          className="reset-button"
                          type="regular"
                          variant="circleX"
                          color="#FF5000"
                          style={{
                            width: '18px',
                            height: '18px',
                            marginTop: '25px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            alignSelf: 'center',
                          }}
                          onClick={resetOnBehalf}
                        />
                      </>
                    ) : null}
                  </>
                )}
                {!differentSalesRep?.value && (
                  <div className="mt-2">
                    <p>Please enter XP Code</p>
                    <Input
                      className="w-100"
                      type="text"
                      placeholder="XP Code"
                      aria-label="XP Code"
                      name="xpCode"
                      withLabel
                      labelType="floating"
                      variant="default"
                      register={register}
                      errors={errors}
                      // disabled={emailRegistered}
                      //style={styleInput}
                    />
                  </div>
                )}
              </div>
              {frontendOverrideSst && (
                <Controller
                  control={control}
                  name="overrideRep"
                  render={({field: {onChange, name}}) => (
                    <RadioGroup
                      data={OVERRIDE_SST_OPTIONS}
                      value={
                        accountInfo?.overrideRepCode
                          ? OVERRIDE_SST_OPTIONS[0]
                          : OVERRIDE_SST_OPTIONS[1]
                      }
                      label="Do you want to override SST on this order?"
                      row
                      layoutStyles={{
                        gap: '10px',
                      }}
                      onChange={onChange}
                      name={name}
                    />
                  )}
                />
              )}
              <div className="d-flex" style={{marginTop: '-20px'}}>
                {/* {overrideRep?.value && (
                  <>
                    <div
                      className="input_container mt-4"
                      style={{width: '300px'}}
                    >
                      <Input
                        className="w-100"
                        type="text"
                        placeholder="Search by ID"
                        aria-label="Search by ID"
                        name="overrideRepCode"
                        withLabel
                        labelType="static"
                        variant="default"
                        disabled={selectedOverrideRep}
                        register={register}
                        errors={errors}
                      />
                    </div>
                    {selectedOverrideRep ? (
                      <>
                        <Icon
                          className="reset-button"
                          type="regular"
                          variant="circleX"
                          color="#FF5000"
                          style={{
                            width: '18px',
                            height: '18px',
                            marginTop: '30px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            alignSelf: 'center',
                          }}
                          onClick={resetSstOnBehalf}
                        />
                        <ParagraphText
                          variant="reg"
                          className="mb-0 text-thryv-stee"
                          style={{marginTop: '29px'}}
                        >
                          {selectedOverrideRep}
                        </ParagraphText>
                      </>
                    ) : (
                      <div className="d-flex align-items-center">
                        <Button
                          data-testid="validate-email-button"
                          variant="primary"
                          className="new-order-button d-flex justify-content-around align-items-center mr-3 mt-4 ml-2"
                          onClick={validateSstOnBehalf}
                        >
                          Validate
                        </Button>
                      </div>
                    )}
                  </>
                )} */}
                {overrideRep?.value && (
                  <>
                    <div
                      className="input_container mt-4"
                      style={{width: '300px'}}
                    >
                      <AsyncSelect
                        cacheOptions
                        placeholder="Search by Name"
                        loadOptions={loadOptions}
                        onChange={handleOverrideRepOptionChange}
                        value={selectedOverrideRep}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: 'silver',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                    {selectedOverrideRep &&
                    selectedOverrideRep.label &&
                    selectedOverrideRep.label.length ? (
                      <>
                        <Icon
                          className="reset-button"
                          type="regular"
                          variant="circleX"
                          color="#FF5000"
                          style={{
                            width: '18px',
                            height: '18px',
                            marginTop: '25px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            alignSelf: 'center',
                          }}
                          onClick={resetSstOnBehalf}
                        />
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </UpdateAddressModalContainer>
    </Modal>
  )
}

export {UpdateAddressModal}
