import React, {useState} from 'react'
import {CatalogSectionContainer} from '../ui/command-center-layout'
import {Header, Button, ParagraphText, withModal} from '@thryvlabs/maverick'
import {
  commandCenterIcon,
  marketingCenterIcon,
  reportingCenterIcon,
  ccCouponIcon,
  businessCenterIcon,
  ccDiscountIcon,
} from '../../assets'
import {CouponModal} from './coupon-modal'
import {ConfirmationModal} from './confirmation-modal'
import {CartItem} from './cart-item'
import {UpdateAddressModal} from '../update-address-modal'
import {COUNTRIES, CC_CURRENCIES} from '../../utils/constants'
import {BC_PLANS, MC_PLANS} from '../../utils/constants'
import {formatPrice} from '../../utils/currency-format'
import {CancelOrderModal} from '../cancel-order-modal'
import {useWindowDimensions} from '../../utils/use-window-dimensions'
import {PlanDiscountsModal} from './plan-discounts-modal'

const DEFAULT_CART_ITEMS = [
  {
    name: 'Command Center',
    img: commandCenterIcon,
  },
  {
    name: 'Business Center',
    img: businessCenterIcon,
  },
  {
    name: 'Marketing Center',
    img: marketingCenterIcon,
  },
]

const APPS_IN_CENTERS = [
  'Thryv Leads',
  'SEO Non-Guaranteed',
  'SEO Money Back Guarantee',
  'SEO',
]

const CENTER_CODES = ['bc', 'cc', 'mc']

const RenderItems = ({
  items,
  filterFunc = () => true,
  isCenter = false,
  selectedCentersAndApps,
  isNewTLMCAccessible,
}) => {
  return items.filter(filterFunc).map(({id, code, name, img, ...content}) => {
    const isListingsApp = code === 'app-listings'
    const isBCorMCInCart = selectedCentersAndApps?.centers.some(
      ({code}) => BC_PLANS.includes(code) || MC_PLANS.includes(code),
    )

    const isNewSEO = selectedCentersAndApps?.centers.find(
      ({code}) => code === 'seobase-5-thryv-m2m',
    )
    let isTLAdded
    if (isNewTLMCAccessible) {
      isTLAdded = selectedCentersAndApps?.centers.find(
        item => item.name === 'Thryv Leads',
      )
    }

    return (
      <CartItem
        key={id || code}
        title={name}
        img={img}
        content={{id, code, name, img, ...content}}
        isCenter={isCenter}
        selectedCentersAndApps={selectedCentersAndApps}
        crossedOut={
          (['seombg-5-thryv-m2m', 'seo-5-thryv-m2m'].includes(code) &&
            isNewSEO) ||
          (['Expanded Growth Package', 'Starter Growth Package'].includes(
            name,
          ) &&
            isTLAdded)
            ? true
            : isListingsApp && isBCorMCInCart
        }
      />
    )
  })
}

function CouponButton({showModal}) {
  return (
    <div
      data-testid="coupon-button"
      onClick={showModal}
      style={{
        border: '1px solid #057AFF',
        borderRadius: '4px',
        padding: '10px 8px',
        cursor: 'pointer',
        backgroundColor: '#057AFF1A',
      }}
    >
      <img
        height="10.5px"
        width="15.88px"
        alt="coupon icon"
        src={ccCouponIcon}
      />
    </div>
  )
}

function OpenModalButton({showModal, variant = 'primary', text}) {
  return (
    <Button variant={variant} onClick={showModal}>
      {text}
    </Button>
  )
}

function PlanDiscountsButton({showModal}) {
  return (
    <div
      data-testid="discount-button"
      onClick={showModal}
      style={{
        border: '1px solid var(--utility-alert-warning, #FAC02B)',
        background:
          'linear-gradient(0deg, rgba(250, 192, 43, 0.10) 0%, rgba(250, 192, 43, 0.10) 100%), #FFF',
        borderRadius: '4px',
        padding: '8px 8px',
        cursor: 'pointer',
        marginLeft: '10px',
      }}
    >
      <img
        height="10.5px"
        width="15.88px"
        alt="coupon icon"
        src={ccDiscountIcon}
      />
    </div>
  )
}

function OrderSummary({
  nextStep,
  selectedCentersAndApps,
  isWebsiteValid,
  code,
  setCode,
  handleStartOver,
  oneTimeFees,
  ccCouponInfo,
  setCcCouponInfo,
  selectedCountry,
  isUpgrade,
  setAccountInfo,
  accountInfo,
  totalPrice,
  defaultCentersAndApps,
  expiredCentersAndApps,
  all5PlanPrices,
  monthlyTotal,
  orderData5CU,
  setOrderData5CU,
  captureOrder5NewUpgrade,
  hasReportingCenterAccess,
  isNewTLMCAccessible,
  keapAccount,
  setKeapAccount,
  hasKeapAccountAbility,
  setHasKeapAccountAbility,
  setDiscountType,
  discountType,
}) {
  const clientGroup = keapAccount ? 'keap' : ''
  const [isAddressUpdated, setIsAddressUpdated] = useState(false)
  const {width: windowWidth} = useWindowDimensions()

  const CouponButtonWithModal = withModal(CouponButton, CouponModal)
  const StartOverButtonWithModal = withModal(OpenModalButton, ConfirmationModal)
  const UpgradeConfirmationButtonWithModal = withModal(
    OpenModalButton,
    ConfirmationModal,
  )
  const PlanDiscountsButtonWithModal = withModal(
    PlanDiscountsButton,
    PlanDiscountsModal,
  )

  const isOnboardingApplicable = !defaultCentersAndApps.centers.some(
    center => center.code.includes('bc') || center.code.includes('mc'),
  )
  const isWebsiteAppAlreadyIncluded = defaultCentersAndApps?.apps?.some(
    ({code}) => code === 'addon-5-website_difm',
  )
  const isWebsiteAddedAsEnt = selectedCentersAndApps?.centers.some(
    ({entitlements}) =>
      entitlements?.some(({code}) => code === 'ent-5-website'),
  )
  const isWebsiteFeeNotApplicable =
    isWebsiteAppAlreadyIncluded && isWebsiteAddedAsEnt
  const isCanAusNz = ['CA', 'AU', 'NZ'].includes(selectedCountry)
  const isAus = selectedCountry === 'AU'
  const isNZ = selectedCountry === 'NZ'
  const isTLInCart = selectedCentersAndApps?.centers.some(
    ({name}) => name === 'Thryv Leads',
  )

  const get24x7SupportFee = () => {
    const {centers} = all5PlanPrices
    const {name, code, currencies, id} = centers.find(
      ({code}) => code === '247support-m2m',
    )
    const amount = currencies.find(
      ({currency}) =>
        currency === CC_CURRENCIES[COUNTRIES[selectedCountry].value],
    ).unitAmount
    const formattedFee = {
      name,
      code,
      quantity: 1,
      amount: amount,
      id,
      entitlements: [],
    }
    return formattedFee
  }

  const handleContinue = () => {
    const defaultCartCodes = [
      ...defaultCentersAndApps.centers,
      ...defaultCentersAndApps.apps,
    ].flatMap(({code, entitlements}) => [
      code,
      ...(entitlements || []).map(e => e.code),
    ])
    const allItemsInCart = [
      ...selectedCentersAndApps.centers,
      ...selectedCentersAndApps.apps,
      ...selectedCentersAndApps.oneTimes,
    ]
    const cartCodes = allItemsInCart
      .filter(({alreadyIncluded}) => !alreadyIncluded)
      .flatMap(({code, entitlements}) => [
        code,
        ...(entitlements || []).map(e => e.code),
      ])
      .filter(code => {
        const centerType =
          CENTER_CODES.some(c => code.includes(c)) && code.split('_')[0]
        return (
          !defaultCartCodes.includes(code) &&
          !defaultCartCodes.some(c => c.includes(centerType))
        )
      })
    let applicableOneTimes = oneTimeFees
      .filter(fee => fee.appliesTo.some(code => cartCodes.includes(code)))
      .filter(
        ({code}) => !selectedCentersAndApps.oneTimes.some(o => o.code === code),
      )
      .filter(({code}) => {
        if (code === 'thryvprfee') {
          return isOnboardingApplicable
        }
        if (code === 'thryvdesg2' && isWebsiteFeeNotApplicable) {
          return false
        }
        return true
      })
    let isFeeRequired = false
    let filteredApps
    const isMCorBCInCart = selectedCentersAndApps?.centers.some(
      ({code}) => BC_PLANS.includes(code) || MC_PLANS.includes(code),
    )
    const isBCInCart = selectedCentersAndApps?.centers.some(({code}) =>
      BC_PLANS.includes(code),
    )
    const isMCInCart = selectedCentersAndApps?.centers.some(({code}) =>
      MC_PLANS.includes(code),
    )
    const isBCInDefault = defaultCentersAndApps?.centers.some(({code}) =>
      BC_PLANS.includes(code),
    )
    const isMCInDefault = defaultCentersAndApps?.centers.some(({code}) =>
      MC_PLANS.includes(code),
    )
    const oldMCCenter = defaultCentersAndApps?.centers.find(
      ({code}) => MC_PLANS.includes(code) && code,
    )
    const newMCCenter = selectedCentersAndApps?.centers.find(
      ({code}) => MC_PLANS.includes(code) && code,
    )
    const oldBCCenter = defaultCentersAndApps?.centers.find(
      ({code}) => BC_PLANS.includes(code) && code,
    )
    const newBCCenter = selectedCentersAndApps?.centers.find(
      ({code}) => BC_PLANS.includes(code) && code,
    )
    const is24X7FeeExisting = defaultCentersAndApps?.centers.some(
      ({code}) => '247support-m2m' === code,
    )
    if (is24X7FeeExisting) {
      isFeeRequired = true
    }
    if (isMCorBCInCart) {
      if (isUpgrade) {
        if (isBCInCart && isMCInCart) {
          if (!isBCInDefault && !isMCInDefault) {
            isFeeRequired = false
          } else if (!isBCInDefault && isMCInDefault) {
            if (oldMCCenter?.code !== newMCCenter?.code) {
              isFeeRequired = false
            } else {
              if (is24X7FeeExisting) {
                isFeeRequired = true
              } else {
                isFeeRequired = false
              }
            }
          } else if (isBCInDefault && !isMCInDefault) {
            if (oldBCCenter?.code !== newBCCenter?.code) {
              isFeeRequired = false
            } else {
              if (is24X7FeeExisting) {
                isFeeRequired = true
              } else {
                isFeeRequired = false
              }
            }
          } else {
            if (
              oldMCCenter?.code !== newMCCenter?.code &&
              oldBCCenter?.code !== newBCCenter?.code
            ) {
              isFeeRequired = false
            } else if (
              oldMCCenter?.code === newMCCenter?.code &&
              oldBCCenter?.code !== newBCCenter?.code
            ) {
              if (is24X7FeeExisting) {
                isFeeRequired = true
              } else {
                isFeeRequired = false
              }
            } else if (
              oldMCCenter?.code !== newMCCenter?.code &&
              oldBCCenter?.code === newBCCenter?.code
            ) {
              if (is24X7FeeExisting) {
                isFeeRequired = true
              } else {
                isFeeRequired = false
              }
            }
          }
        } else if (isBCInCart && !isMCInCart) {
          if (isBCInDefault) {
            if (oldBCCenter?.code !== newBCCenter?.code) {
              isFeeRequired = false
            }
          } else {
            isFeeRequired = false
          }
        } else if (isMCInCart && !isBCInCart) {
          if (isMCInDefault) {
            if (oldMCCenter?.code !== newMCCenter?.code) {
              isFeeRequired = false
            }
          } else {
            isFeeRequired = false
          }
        }
      } else {
        isFeeRequired = false
      }
    }
    if (!isFeeRequired) {
      filteredApps = selectedCentersAndApps.centers.filter(
        center => center.code !== '247support-m2m',
      )
    } else {
      const isSupportFeeInCart = selectedCentersAndApps.centers.some(
        ({code}) => code === '247support-m2m',
      )
      if (!isSupportFeeInCart) {
        const supportFee = get24x7SupportFee()
        filteredApps = [...selectedCentersAndApps.centers]
        filteredApps.push(supportFee)
      } else {
        filteredApps = [...selectedCentersAndApps.centers]
      }
    }

    const selectedCentersAndAppsFinal = {
      ...selectedCentersAndApps,
      ...(filteredApps && {centers: filteredApps}),
      oneTimes: [...selectedCentersAndApps.oneTimes, ...applicableOneTimes],
    }
    console.log(
      'order summary - selectedCentersAndAppsFinal',
      selectedCentersAndAppsFinal,
    )
    console.log('order summary - defaultCentersAndApps', defaultCentersAndApps)
    // const orderLines = []
    const orderLines = getOrderLines()
    captureOrder5NewUpgrade(
      'catalog-continue-summary',
      'new-upgrade',
      orderLines,
    )

    nextStep({
      selectedCentersAndApps: selectedCentersAndAppsFinal,
      code,
    })
  }

  const getSassProductQuantity = center => {
    if (center.code === 'seobase-5-thryv-m2m') {
      if (center.tempLineActionCode && center.tempLineActionCode === 'Update') {
        return center.amount
      } else if (
        center.tempLineActionCode &&
        center.tempLineActionCode === 'New'
      ) {
        return center.quantity
      }
    } else {
      return center.quantity ? center.quantity : 1
    }
  }
  const getSassProductPriceAndUamount = (state, center) => {
    if (center.code === 'seobase-5-thryv-m2m') {
      if (center.tempLineActionCode && center.tempLineActionCode === 'Update') {
        const amount = center.quantity
        const quantity = center.amount
        const pUAmount = amount || amount === 0 ? amount : null
        let pUPrice = pUAmount
        if (pUAmount) {
          pUPrice = pUAmount * (quantity ? quantity : 1)
        }
        return state === 'uPrice' ? pUPrice : pUAmount
      } else if (
        center.tempLineActionCode &&
        center.tempLineActionCode === 'New'
      ) {
        const amount = center.amount
        const quantity = center.quantity
        const pUAmount = amount || amount === 0 ? amount : null
        let pUPrice = pUAmount
        if (pUAmount) {
          pUPrice = pUAmount * (quantity ? quantity : 1)
        }
        return state === 'uPrice' ? pUPrice : pUAmount
      }
    } else {
      let pUPrice =
        center.unitAmount || center.unitAmount === 0
          ? center.unitAmount
          : center.amount || center.amount === 0
          ? center.amount
          : null
      if (pUPrice) {
        pUPrice = pUPrice * (center.quantity ? center.quantity : 1)
      }
      return pUPrice
    }
  }

  const getOrderLines = () => {
    const orderLines = []
    const selectedCenters = []
    const selectedApps = []
    const selectedOneTimes = []
    const sCentersAndApps = {...selectedCentersAndApps}
    const dCentersAndApps = {...defaultCentersAndApps}
    if ((sCentersAndApps.centers, dCentersAndApps.centers)) {
      selectedCenters.push(
        ...getAllSelectedDefaultLines(
          'center',
          sCentersAndApps.centers,
          dCentersAndApps.centers,
        ),
      )
    }
    if ((sCentersAndApps.apps, dCentersAndApps.apps)) {
      const tempDApps = []
      const tempSApps = []
      // dCentersAndApps?.apps?.forEach(dLine => {
      //   const foundInArray2 =
      //     dLine.code === 'addon-5-signatures_usage' ||
      //     dLine.code === 'app-websitebuilder'
      //   if (!foundInArray2) tempDApps.push(dLine)
      // })
      // sCentersAndApps?.apps?.forEach(sLine => {
      //   const foundInArray2 =
      //     sLine.code === 'addon-5-signatures_usage' ||
      //     sLine.code === 'app-websitebuilder'
      //   if (!foundInArray2) tempSApps.push(sLine)
      // })

      dCentersAndApps?.apps?.forEach(dLine => {
        tempDApps.push(dLine)
      })
      dCentersAndApps?.apps?.forEach(dLine => {
        if (dLine.addons) {
          dLine.addons.forEach(aLine => {
            const foundInArray2 = tempDApps?.findIndex(
              tLine => tLine.code === aLine.code,
            )
            if (foundInArray2 >= 0) tempDApps.splice(foundInArray2, 1)
          })
        }
      })
      sCentersAndApps?.apps?.forEach(dLine => {
        tempSApps.push(dLine)
      })
      sCentersAndApps?.apps?.forEach(dLine => {
        if (dLine.addons) {
          dLine.addons.forEach(aLine => {
            const foundInArray2 = tempSApps?.findIndex(
              tLine => tLine.code === aLine.code,
            )
            if (foundInArray2 >= 0) tempSApps.splice(foundInArray2, 1)
          })
        }
      })

      selectedApps.push(
        ...getAllSelectedDefaultLines('app', tempSApps, tempDApps),
      )
    }
    if ((sCentersAndApps.oneTimes, dCentersAndApps.oneTimes)) {
      selectedOneTimes.push(
        ...getAllSelectedDefaultLines(
          'oneTime',
          sCentersAndApps.oneTimes,
          dCentersAndApps.oneTimes,
        ),
      )
    }
    const selectedCenterLines = []
    let line_counter = 1
    if (selectedCenters) {
      for (let center of selectedCenters) {
        // let pUPrice =
        //   center.unitAmount || center.unitAmount === 0
        //     ? center.unitAmount
        //     : center.amount || center.amount === 0
        //     ? center.amount
        //     : null
        // if (pUPrice) {
        //   pUPrice = pUPrice * (center.quantity ? center.quantity : 1)
        // }
        selectedCenterLines.push({
          line_number: line_counter,
          parent_line_number: null,
          plansv5_id: center.id,
          action_code: center.tempLineActionCode,
          order_line_status: 'InProgress',
          saas_product_category: 'Center',
          saas_product_type: 'main',
          saas_product_code: center.code,
          saas_instance_id: null,
          recurly_subscription_id: center.uuid ? center.uuid : null,
          total_cost: getSassProductPriceAndUamount('uPrice', center),
          unit_cost: getSassProductPriceAndUamount('uAmount', center),
          // saas_product_price: getSassProductPriceAndUamount('uPrice', center),
          // saas_product_unit_amount: getSassProductPriceAndUamount(
          //   'uAmount',
          //   center,
          // ),
          quantity: getSassProductQuantity(center),
          // quantity: center.quantity,
          // one_time_fee: null,
          start_date: new Date(),
          end_date: null,
        })
        let p_lc = line_counter
        line_counter = line_counter + 1

        if (center.entitlements) {
          for (let entitlement of center.entitlements) {
            // if (!entitlement.isRequired) {
            const cUAmount =
              entitlement.unitAmount || entitlement.unitAmount === 0
                ? entitlement.unitAmount
                : entitlement.amount || entitlement.amount === 0
                ? entitlement.amount
                : 0
            let cUPrice = cUAmount
            if (cUAmount) {
              cUPrice =
                cUAmount * (entitlement.quantity ? entitlement.quantity : 1)
            }
            selectedCenterLines.push({
              line_number: line_counter,
              parent_line_number: p_lc,
              plansv5_id: entitlement.id,
              action_code: entitlement.tempLineActionCode
                ? entitlement.tempLineActionCode
                : center.tempLineActionCode,
              order_line_status: 'InProgress',
              saas_product_category: 'Center',
              saas_product_type: 'entitlement',
              saas_product_code: entitlement.code,
              saas_instance_id: null,
              recurly_subscription_id: entitlement.uuid
                ? entitlement.uuid
                : entitlement.subscriptionId
                ? entitlement.subscriptionId
                : null,
              total_cost: cUPrice,
              unit_cost: cUAmount,
              // saas_product_price: cUPrice,
              // saas_product_unit_amount: cUAmount,
              quantity: entitlement.quantity ? entitlement.quantity : 1,
              // one_time_fee: null,
              start_date: new Date(),
              end_date: null,
            })
            line_counter = line_counter + 1
            // }
          }
        }
      }
    }
    const selectedAppLines = []
    if (selectedApps) {
      for (let app of selectedApps) {
        const pUAmount =
          app.unitAmount || app.unitAmount === 0
            ? app.unitAmount
            : app.amount || app.amount === 0
            ? app.amount
            : null
        let pUPrice = pUAmount
        if (pUAmount) {
          pUPrice = pUAmount * (app.quantity ? app.quantity : 1)
        }
        selectedAppLines.push({
          line_number: line_counter,
          parent_line_number: null,
          plansv5_id: app.id,
          action_code: app.tempLineActionCode,
          order_line_status: 'InProgress',
          saas_product_category: 'App',
          saas_product_type: 'addon',
          saas_product_code: app.code,
          saas_instance_id: null,
          recurly_subscription_id: app.uuid
            ? app.uuid
            : app.subscriptionId
            ? app.subscriptionId
            : null,
          total_cost: pUPrice,
          unit_cost: pUAmount,
          // saas_product_price: pUPrice,
          // saas_product_unit_amount: pUAmount,
          quantity: app.quantity ? app.quantity : 1,
          // one_time_fee: null,
          start_date: new Date(),
          end_date: null,
        })
        let p_lc = line_counter
        line_counter = line_counter + 1
        if (app.addons) {
          for (let addon of app.addons) {
            const cUAmount =
              addon.unitAmount || addon.unitAmount === 0
                ? addon.unitAmount
                : addon.amount || addon.amount === 0
                ? addon.amount
                : null
            let cUPrice = cUAmount
            if (cUAmount) {
              cUPrice = cUAmount * (addon.quantity ? addon.quantity : 1)
            }
            selectedAppLines.push({
              line_number: line_counter,
              parent_line_number: p_lc,
              plansv5_id: addon.id,
              action_code: addon.tempLineActionCode
                ? addon.tempLineActionCode
                : app.tempLineActionCode,
              order_line_status: 'InProgress',
              saas_product_category: 'App',
              saas_product_type: 'addon',
              saas_product_code: addon.code,
              saas_instance_id: null,
              recurly_subscription_id: addon.uuid
                ? addon.uuid
                : addon.subscriptionId
                ? addon.subscriptionId
                : null,
              total_cost: cUPrice,
              unit_cost: cUAmount,
              // saas_product_price: cUPrice,
              // saas_product_unit_amount: cUAmount,
              quantity: addon.quantity ? addon.quantity : 1,
              // one_time_fee: null,
              start_date: new Date(),
              end_date: null,
            })
            line_counter = line_counter + 1
          }
        }
      }
    }
    // const uniqueSelectedAppLines = selectedAppLines.reduce((acc, obj) => {
    //   if (!acc.some(o => o.saas_product_code === obj.saas_product_code)) {
    //     acc.push(obj)
    //   }
    //   return acc
    // }, [])
    const selectedOneTimeLines = []
    if (selectedOneTimes) {
      for (let oneTime of selectedOneTimes) {
        const pUAmount =
          oneTime.unitAmount || oneTime.unitAmount === 0
            ? oneTime.unitAmount
            : oneTime.amount || oneTime.amount === 0
            ? oneTime.amount
            : null
        let pUPrice = pUAmount
        if (pUAmount) {
          pUPrice = pUAmount * (oneTime.quantity ? oneTime.quantity : 1)
        }
        selectedOneTimeLines.push({
          line_number: line_counter,
          parent_line_number: null,
          plansv5_id: oneTime.id,
          action_code: oneTime.tempLineActionCode,
          order_line_status: 'InProgress',
          saas_product_category: 'OneTimeFee',
          saas_product_type: 'oneTimeFee',
          saas_product_code: oneTime.code,
          saas_instance_id: null,
          recurly_subscription_id: oneTime.uuid
            ? oneTime.uuid
            : oneTime.subscriptionId
            ? oneTime.subscriptionId
            : null,
          total_cost: pUPrice,
          unit_cost: pUAmount,
          // saas_product_price: pUPrice,
          // saas_product_unit_amount: pUAmount,
          quantity: oneTime.quantity ? oneTime.quantity : 1,
          // one_time_fee: null,
          start_date: new Date(),
          end_date: null,
        })
        let p_lc = line_counter
        line_counter = line_counter + 1
        if (oneTime.entitlements) {
          for (let entitlement of oneTime.entitlements) {
            const cUAmount =
              entitlement.unitAmount || entitlement.unitAmount === 0
                ? entitlement.unitAmount
                : entitlement.amount || entitlement.amount === 0
                ? entitlement.amount
                : null
            let cUPrice = cUAmount
            if (cUAmount) {
              cUPrice =
                cUAmount * (entitlement.quantity ? entitlement.quantity : 1)
            }
            selectedOneTimeLines.push({
              line_number: line_counter,
              parent_line_number: p_lc,
              plansv5_id: entitlement.id,
              action_code: entitlement.tempLineActionCode
                ? entitlement.tempLineActionCode
                : oneTime.tempLineActionCode,
              order_line_status: 'InProgress',
              saas_product_category: 'OneTimeFee',
              saas_product_type: 'entitlement',
              saas_product_code: entitlement.code,
              saas_instance_id: null,
              recurly_subscirption_id: entitlement.uuid
                ? entitlement.uuid
                : entitlement.subscriptionId
                ? entitlement.subscriptionId
                : null,
              total_cost: cUPrice,
              unit_cost: cUAmount,
              // saas_product_price: cUPrice,
              // saas_product_unit_amount: cUAmount,
              quantity: entitlement.quantity ? entitlement.quantity : 1,
              // one_time_fee: null,
              start_date: new Date(),
              end_date: null,
            })
            line_counter = line_counter + 1
          }
        }
      }
    }
    console.log('selectedCenterLines', selectedCenterLines)
    console.log('selectedAppLines', selectedAppLines)
    // console.log('uniqueSelectedAppLines', uniqueSelectedAppLines)
    console.log('selectedOneTimeLines', selectedOneTimeLines)

    orderLines.push(
      ...selectedCenterLines,
      ...selectedAppLines,
      // ...uniqueSelectedAppLines,
      ...selectedOneTimeLines,
    )
    console.log('orderLines', orderLines)
    return orderLines
  }

  const getAllSelectedDefaultLines = (prodCat, lines1, lines2) => {
    const sLines = [...lines1]
    const dLines = [...lines2]
    // const tempOrderLines = []
    const selectedandDefaultlines = []
    // const defaultLines = []

    dLines?.forEach(dLine => {
      let found = false
      sLines?.forEach(sLine => {
        if (dLine.code === sLine.code) {
          dLine.tempLineActionCode = 'Update'
          found = true
        }
      })
      if (!found) {
        dLine.tempLineActionCode = 'Cancel'
      }
      selectedandDefaultlines.push(dLine)
    })

    sLines?.forEach(sLine => {
      const foundInArray2 = dLines?.find(dLine => dLine.code === sLine.code)
      sLine.tempLineActionCode = foundInArray2 ? 'Update' : 'New'
      if (!foundInArray2) selectedandDefaultlines.push(sLine)
    })

    console.log(
      'selectedandDefaultlines - updated action code - ' + prodCat,
      selectedandDefaultlines,
    )
    // console.log('defaultLines - ' + prodCat, defaultLines)

    // tempOrderLines.push(...selectedlines, ...defaultLines)
    // console.log('tempOrderLines - ' + prodCat, tempOrderLines)

    return selectedandDefaultlines
  }

  const saveBillingAddress = data => {
    let saveData = {
      ...accountInfo,
      recurlyFirst: data.firstName,
      recurlyLast: data.lastName,
      recurlyCompany: data.company,
      salesRepCode: data.salesRepCode,
      salesRepInfo: data.salesRepInfo,
      behalfEmail: data.behalfEmail,
      behalfFirst: data.behalfFirst,
      behalfLast: data.behalfLast,
      xpCode: data.xpCode,
      overrideRepInfo: data.overrideRepInfo,
      overrideRepCode: data.overrideRepCode,
      overrideRepEmail: data.overrideRepEmail,
      overrideRepFirst: data.overrideRepFirst,
      overrideRepLast: data.overrideRepLast,
      abn: data.abn,
      billingAddress: {
        ...accountInfo.billingAddress,
        street1: data.street1,
        city: data.city,
        state: data.region,
        zipCode: data.postalCode,
        phone: data.phone,
        mobile: data.mobile,
      },
    }
    if (!isAus && !isNZ) {
      delete saveData.abn
    }
    if (!data.salesRepCode) {
      delete saveData.salesRepInfo
      delete saveData.salesRepCode
      delete saveData.behalfEmail
      delete saveData.behalfFirst
      delete saveData.behalfLast
    }
    if (!data.xpCode) {
      delete saveData.xpCode
    }
    if (!data.overrideRepCode) {
      delete saveData.overrideRepInfo
      delete saveData.overrideRepCode
      delete saveData.overrideRepEmail
      delete saveData.overrideRepFirst
      delete saveData.overrideRepLast
    }
    setAccountInfo(saveData)
    setOrderData5CU({
      ...orderData5CU,
      accountInfo: {...orderData5CU.accountInfo, ...saveData},
    })
  }

  const renderNextAction = () => {
    if (isUpgrade && !isAddressUpdated) {
      return (
        <>
          {windowWidth > 1260 && <CancelOrderModal cancelUrl="/orders" />}
          <UpdateAddressModal
            nextStep={saveBillingAddress}
            selectedCountry={selectedCountry}
            accountInfo={accountInfo}
            is5Order={true}
            setIsAddressUpdated={setIsAddressUpdated}
            hasKeapAccountAbility={hasKeapAccountAbility}
            setHasKeapAccountAbility={setHasKeapAccountAbility}
            keapAccount={keapAccount}
            setKeapAccount={setKeapAccount}
            handleStartOver={handleStartOver}
          />
        </>
      )
    }
    if (isUpgrade && isAddressUpdated) {
      return (
        <>
          {windowWidth > 1260 && <CancelOrderModal cancelUrl="/orders" />}
          <UpgradeConfirmationButtonWithModal
            parentProps={{
              variant: 'primary',
              text: 'Continue',
            }}
            modalProps={{
              title: 'Are you sure you want to change your plan?',
              height: '200px',
              handleAction: handleContinue,
            }}
          />
        </>
      )
    }
    return (
      <>
        {windowWidth > 1425 && <CancelOrderModal cancelUrl="/orders" />}
        <Button variant="primary" onClick={handleContinue}>
          Continue
        </Button>
      </>
    )
  }
  if (
    selectedCountry === 'US' &&
    !DEFAULT_CART_ITEMS.some(center => center.name === 'Reporting Center')
  ) {
    DEFAULT_CART_ITEMS.push({
      name: 'Reporting Center',
      img: reportingCenterIcon,
    })
  } else if (selectedCountry !== 'US') {
    const index = DEFAULT_CART_ITEMS.findIndex(
      center => center.name === 'Reporting Center',
    )
    if (index !== -1) {
      DEFAULT_CART_ITEMS.splice(index, 1)
    }
  }

  const filteredCartItems = DEFAULT_CART_ITEMS.filter(
    item =>
      !(selectedCountry === 'KY' || selectedCountry === 'BB') ||
      item.name !== 'Marketing Center',
  )

  return (
    <CatalogSectionContainer
      className="h-100 w-100"
      style={{
        backgroundColor: '#F8F9FB',
        padding: '17px 20px 0 20px',
        borderRadius: '4px',
        position: 'relative',
        height: '800px',
      }}
    >
      <div>
        <div className="d-flex justify-content-between">
          <Header variant="h4" fontWeight="semibold">
            Your Order
          </Header>
          <StartOverButtonWithModal
            parentProps={{variant: 'secondary', text: 'Start Over'}}
            modalProps={{
              title: 'Are you sure you want to \nstart over?',
              subtitle: 'If you start over, your changes will not be saved',
              handleAction: handleStartOver,
            }}
          />
        </div>
      </div>
      <div style={{height: '570px', overflowY: 'auto'}}>
        <div style={{paddingRight: '20px'}}>
          <div style={{paddingTop: '21px'}}>
            <ParagraphText className="font-weight-bold" variant="lg">
              Centers
            </ParagraphText>
            {filteredCartItems.map(({name, img}) => {
              const content = selectedCentersAndApps?.centers.find(
                ({name: n}) => n.toLowerCase().includes(name.toLowerCase()),
              )
              return (
                <CartItem
                  key={name}
                  title={name}
                  img={img}
                  content={content}
                  isCenter={true}
                  isCanAusNz={isCanAusNz}
                />
              )
            })}
          </div>
          <div style={{paddingTop: '21px'}}>
            <ParagraphText className="font-weight-bold" variant="lg">
              Apps
            </ParagraphText>
            {selectedCentersAndApps?.apps.length > 0 ||
            selectedCentersAndApps?.oneTimes.length > 0 ||
            isTLInCart ? (
              <>
                {selectedCentersAndApps?.centers && (
                  <RenderItems
                    items={selectedCentersAndApps.centers}
                    filterFunc={({name}) => APPS_IN_CENTERS.includes(name)}
                    isCenter
                    isNewTLMCAccessible={isNewTLMCAccessible}
                  />
                )}
                {selectedCentersAndApps?.apps && (
                  <RenderItems
                    items={selectedCentersAndApps.apps}
                    selectedCentersAndApps={selectedCentersAndApps}
                    isNewTLMCAccessible={isNewTLMCAccessible}
                  />
                )}
              </>
            ) : (
              <p style={{color: '#A3A5A7', fontSize: '14px'}}>None Selected</p>
            )}
          </div>
          <div style={{paddingTop: '21px'}}>
            {selectedCentersAndApps?.oneTimes?.length > 0 && (
              <>
                <ParagraphText className="font-weight-bold" variant="lg">
                  One Time Fees
                </ParagraphText>
                <RenderItems
                  items={selectedCentersAndApps.oneTimes.sort((a, b) =>
                    a.name.includes('Fee')
                      ? 1
                      : b.name.includes('Fee')
                      ? -1
                      : 0,
                  )}
                  isNewTLMCAccessible={isNewTLMCAccessible}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between"
        style={{
          height: '35px',
          marginTop: '15px',
          borderTop: 'solid 2px #dee2e6',
        }}
      >
        <ParagraphText variant="lg" className="mt-2">
          {' '}
          <strong>Total</strong>
        </ParagraphText>
        <ParagraphText variant="lg" className="mt-2">
          <strong>{formatPrice(totalPrice)}*</strong>
        </ParagraphText>
      </div>
      <div className="d-flex justify-content-between">
        <ParagraphText variant="sm"> </ParagraphText>
        <ParagraphText variant="sm" style={{opacity: '50%'}}>
          *
          {isAus
            ? 'excludes fees and incentives'
            : isNZ
            ? 'excludes fees and incentives'
            : 'excludes taxes, fees, and incentives'}
        </ParagraphText>
      </div>
      <div className="bottom-container">
        {isAus && <p>*All Plans include GST except SEO</p>}
        <div className="buttons-container">
          <div className="d-flex">
            <CouponButtonWithModal
              modalProps={{
                code,
                setCode,
                ccCouponInfo,
                setCcCouponInfo,
                selectedCountry,
                selectedCentersAndApps,
              }}
            />
          </div>
          <PlanDiscountsButtonWithModal
            modalProps={{
              ccCouponInfo,
              setCcCouponInfo,
              selectedCountry,
              selectedCentersAndApps,
              defaultCentersAndApps,
              expiredCentersAndApps,
              clientGroup,
            }}
          />
          <div className="d-flex">{renderNextAction()}</div>
        </div>
      </div>
    </CatalogSectionContainer>
  )
}

export {OrderSummary}
