import {Icon} from '@thryvlabs/maverick'
import React from 'react'
import {FooterContainer, FooterLinksContainer} from './ui/footer'

function FooterLink({text, icon, className = '', styles = {}, url}) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={`mr-3 d-flex justify-content-evenly align-content-center ${className}`}
    >
      <Icon type="solid" variant={icon} color="#a9a9a9" className="mr-2" />
      {text}
    </a>
  )
}

function Footer() {
  const currentYear = new Date().getFullYear()
  return (
    <FooterContainer className="footer p-2 pr-5 pl-5 d-flex justify-content-between align-content-center">
      <span>&copy;{currentYear} Thryv. All rights reserved.</span>
      <FooterLinksContainer className="d-flex justify-content-between align-content-center">
        <FooterLink text="Support" icon="circleQuestion" url="/support" />
      </FooterLinksContainer>
    </FooterContainer>
  )
}

export {Footer}
