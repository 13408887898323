import React, {useState, useEffect} from 'react'
import {Icon, Input, Button, Select, Modal} from '@thryvlabs/maverick'
import {ADD_ON} from '../../utils/addons-data'
import {SEO_MBG_PRICES} from '../../utils/constants'
import {AddOnItem} from './add-on-item'
import {ADDONS_TYPES} from '../../utils/constants'
import {useAuthClient} from '../../utils/use-auth-client'
import {toast} from 'react-toastify'
import {AddOnsDatePickerModal} from './addons-datepicker-modal'
import {Checkbox} from '@thryvlabs/maverick'

const SEO_ADD_ONS = ['SEO Money Back Guarantee', 'SEO Non-Guaranteed']

function ThryvValidatedAddons({
  item,
  addProduct,
  removeProduct,
  updateProduct,
  selectedCountry,
  radioButtonOptions,
  listOfProducts,
  billingPreference,
  currentAddOns,
  isTLInCart,
  isSEOInCart,
  seoMbgItem,
  isSeoMbgItemDefault,
  isCSNInCart,
  isDisabled = false,
  setShouldIncludeCSNAddon,
  addBorderBottom,
}) {
  const client = useAuthClient()
  const [product, setProduct] = useState({})
  const [showSeoSwitchElement, setShowSeoSwitchElement] =
    useState(isSeoMbgItemDefault)
  const [showThryvLeadInput, setShowThryvLeadInput] = useState(false)
  const [thryvLeadId, setThryvLeadId] = useState('')
  const [productId, setProductId] = useState()
  const [selectedPlan, setSelectedPlan] = useState(billingPreference)
  const [leadValidation, setLeadValidation] = useState({})
  const [showProposalIdInput, setShowProposalIdInput] = useState(false)
  const [proposalId, setProposalId] = useState('')
  const [proposalValidation, setProposalValidation] = useState({})
  const [thryvLeadBillingDate, setThryvLeadBillingDate] = useState()
  const [seoBillingDate, setSeoBillingDate] = useState()
  const [selectedSeoMbgPrice, setSelectedSeoMbgPrice] = useState({
    name: '',
    value: 0,
  })
  const [itemName, setItemName] = useState(item.name)
  const [isChecked, setIsChecked] = useState(false)
  const [showModalInfo, setShowModalInfo] = useState(false)
  const [{code}] = item[selectedPlan]
  const singleOption = radioButtonOptions
    .filter(o => o.value === billingPreference)
    .map(o => ({...o, default: true}))
  const thryvLeadsQuantity = currentAddOns?.find(
    ({name}) => name === 'Thryv Leads',
  )?.quantity

  const removeSelectedProduct = productId => {
    if (productId) {
      removeProduct([productId])
      setProductId(null)
    }
  }

  const formatPrice = (budget, plan = null) => {
    const _selectedPlan = plan || selectedPlan
    const price = (_selectedPlan === 'monthly' ? budget : budget * 6)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `$${price}`
  }

  const getProduct = budget => ({
    id: item[selectedPlan][0].id,
    product: item.name,
    type: ADDONS_TYPES['ADD_ON'],
    price: formatPrice(budget),
    planCode: item[selectedPlan][0].code,
  })

  const validateThryvLeadId = async () => {
    removeSelectedProduct(productId)

    const validation = await client(`plans/validate-thryv-leads/${thryvLeadId}`)
    if (thryvLeadsQuantity > validation.budget) {
      toast.error(
        `Error: Thryv Leads budget can't be lower than $${thryvLeadsQuantity.toLocaleString(
          'en-US',
          {minimumFractionDigits: 2, maximumFractionDigits: 2},
        )}`,
      )
      return
    }
    setLeadValidation(validation)
    if (validation.passed) {
      const product = {
        ...getProduct(validation.budget),
        thryvLeadsId: thryvLeadId,
        quantity: validation.budget,
        billingPreference: billingPreference,
      }
      if (isTLInCart) {
        removeProduct(['already-included-Thryv Leads'])
      }
      setProductId(product.id)
      setProduct(product)
      addProduct([product])
      toast.success('Valid campaign ID provided.')
    } else {
      toast.error('Error: The campaign ID entered was not found.')
    }
  }

  const validateProposalId = async () => {
    removeSelectedProduct(productId)
    try {
      const validation = await client(`orders/validate-proposal/${proposalId}`)
      setProposalValidation(validation)
      if (validation.isValidProposal) {
        const seoMbgPrice = {
          name: '$' + String(validation.price),
          value: validation.price,
        }
        const product = {
          ...getProduct(validation.price),
          seoProposalId: proposalId,
          billingPreference: billingPreference,
        }
        if (isSEOInCart) {
          removeProduct([`already-included-${isSEOInCart.product}`])
        }
        setProductId(product.id)
        setProduct(product)
        addProduct([product])
        setSelectedSeoMbgPrice(seoMbgPrice)
        toast.success('Valid proposal ID provided.')
      }
    } catch (error) {
      if (error.status === 404) {
        toast.error('Error: The proposal ID entered was not found.')
      } else if (error.status === 500) {
        toast.error('Error: The proposal ID entered was not found.')
      }
    }
  }

  const handleThryvLeadIdChangeValue = e => {
    setThryvLeadId(e.target.value)
  }

  const handleProposalIdChangeValue = e => {
    setProposalId(e.target.value)
  }

  const toggleThryvLeadsAddRemoveButton = () => {
    setThryvLeadId('')
    if (productId) {
      removeProduct([productId])
      setProductId(null)
      setLeadValidation({})
    }
    if (isCSNInCart) {
      if (!showThryvLeadInput) {
        setShowModalInfo(true)
        return
      }
      if (showThryvLeadInput) {
        setShouldIncludeCSNAddon(true)
      }
    }
    setShowThryvLeadInput(!showThryvLeadInput)
  }

  const toggleProposalIdAddRemoveButton = () => {
    setProposalId('')
    if (productId) {
      removeProduct([productId])
      setProductId(null)
      setProposalValidation({})
      setItemName(
        isSeoMbgItemDefault ? 'SEO Money Back Guarantee' : 'SEO Non-Guaranteed',
      )
      setIsChecked(false)
    }
    setShowProposalIdInput(!showProposalIdInput)
  }

  const updateProductWithPlan = (product, leadValidation, plan) => {
    const pId = item[plan][0].id
    const price = formatPrice(leadValidation.budget, plan)
    const updatedProduct = {
      ...product,
      id: pId,
      price: price,
      planCode: item[selectedPlan][0].code,
    }
    return updatedProduct
  }

  const handlePlanSelection = e => {
    const plan = e.value
    setSelectedPlan(plan)
    if (productId && leadValidation.passed) {
      const updatedProduct = updateProductWithPlan(
        product,
        leadValidation,
        plan,
      )
      updateProduct(updatedProduct, 'product')
    }
  }

  const handleLeadEdit = () => {
    setLeadValidation({...leadValidation, passed: false})
  }

  const handleProposalEdit = e => {
    setProposalValidation({...proposalValidation, isValidProposal: false})
  }

  const replaceSeoProducts = () => {
    removeProduct([product.id])
    const planItem = product.product === item.name ? seoMbgItem : item
    const productToAdd = {
      ...product,
      id: planItem[selectedPlan][0].id,
      product: planItem.name,
      planCode: planItem[selectedPlan][0].code,
    }
    setProductId(productToAdd.id)
    addProduct([productToAdd])
    setProduct(productToAdd)
  }

  const handleChangeSeo = () => {
    setIsChecked(!isChecked)
    const itemNameToUpdate =
      itemName === SEO_ADD_ONS[0] ? SEO_ADD_ONS[1] : SEO_ADD_ONS[0]
    setItemName(itemNameToUpdate)
    replaceSeoProducts(itemNameToUpdate)
    if (itemNameToUpdate === SEO_ADD_ONS[1]) {
      toast.warning('Attn: SEO is no longer guaranteed.')
    }
  }

  const updateSeoProduct = () => {
    if (SEO_ADD_ONS.includes(product.product)) {
      const productToUpdate = {
        ...product,
        price: formatPrice(selectedSeoMbgPrice.value),
      }
      productToUpdate.quantity = selectedSeoMbgPrice.value
      if (isSeoMbgItemDefault) {
        setShowSeoSwitchElement(selectedSeoMbgPrice.value >= 1200)
      }
      setProduct(productToUpdate)
      updateProduct(productToUpdate)
    }
  }

  const clearCSN = () => {
    setShowModalInfo(false)
    setShowThryvLeadInput(!showThryvLeadInput)
    const csnToRemove = listOfProducts.find(
      ({product}) => product === ADD_ON.add_ons['Thryv CSN'].label,
    )
    if (!csnToRemove) return
    removeProduct([csnToRemove.id])
  }
  const addCSN = () => {
    setShowModalInfo(false)
    setShouldIncludeCSNAddon(true)
  }

  useEffect(() => {
    updateSeoProduct()
  }, [selectedSeoMbgPrice, selectedPlan]) //eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (thryvLeadBillingDate) {
      const product1 = {...product, thryvLeadBillingDate}
      setProduct(product1)
      updateProduct(product1, 'product')
    } else {
      const product1 = {...product}
      delete product1.thryvLeadBillingDate
      setProduct(product1)
      updateProduct(product1, 'product')
    }
  }, [thryvLeadBillingDate]) //eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (seoBillingDate) {
      const product1 = {...product, seoBillingDate}
      //
      setProduct(product1)
      updateProduct(product1, 'product')
    } else {
      const product1 = {...product}
      delete product1.seoBillingDate
      setProduct(product1)
      updateProduct(product1, 'product')
    }
  }, [seoBillingDate]) //eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (proposalValidation.isValidProposal) {
      if (!showSeoSwitchElement) {
        if (isChecked) return
        setItemName(seoMbgItem.name)
        replaceSeoProducts(seoMbgItem.name)
      }
      if (showSeoSwitchElement && !isChecked) {
        setItemName(SEO_ADD_ONS[0])
        replaceSeoProducts(SEO_ADD_ONS[0])
      }
      if (itemName !== SEO_ADD_ONS[1]) {
        toast.warning('Attn: SEO is no longer guaranteed.')
      }
    }
  }, [showSeoSwitchElement]) //eslint-disable-line react-hooks/exhaustive-deps
  let isSEOAlreadyInCart = false
  isSEOAlreadyInCart = currentAddOns.some(item =>
    SEO_ADD_ONS.includes(item.name),
  )
  return (
    <>
      {showModalInfo && (
        <Modal
          altBtn={<></>}
          openOnLoad
          onClose={() => setShowModalInfo(false)}
          variant="default"
        >
          <h1 className="text-center font-open-sans font-normal mb-4 ">
            CSN is not an available option when selecting Thryv Leads.
          </h1>
          <p className="text-center">
            Please confirm you&apos;d like to cancel CSN to add on Thryv Leads.
          </p>
          <p className="text-center">
            * CSN will be cancelled after the order is completed.
          </p>
          <div className="d-flex justify-content-around text-center ">
            <Button variant="primary" onClick={clearCSN}>
              Yes
            </Button>
            <Button variant="secondary" onClick={addCSN}>
              No
            </Button>
          </div>
        </Modal>
      )}
      <AddOnItem
        key={item.name}
        productId={item.id}
        title={itemName}
        code={code}
        description={ADD_ON.add_ons[item.name]?.description}
        options={
          billingPreference === 'monthly' ? singleOption : radioButtonOptions
        }
        setState={handlePlanSelection}
        addonType="addons"
        listOfProducts={listOfProducts}
        isDisabled={isDisabled}
        addBorderBottom={addBorderBottom}
        currentAddOns={currentAddOns}
      >
        {!isDisabled && (
          <>
            <div className="row ml-3 mb-4">
              {item.name === 'Thryv Leads' &&
                !leadValidation.passed &&
                !showThryvLeadInput && (
                  <Button
                    className="mr-5"
                    style={{pointerEvents: 'none'}}
                    buttonType="outlined"
                    variant="primary"
                  >
                    {leadValidation.budget || thryvLeadsQuantity || 0}
                  </Button>
                )}

              {showThryvLeadInput && (
                <>
                  <Input
                    className="w-[120px] ml-3"
                    type="text"
                    placeholder="Thryv Leads ID"
                    name="budget"
                    withLabel
                    labelType="static"
                    variant="input-isolated"
                    symbol=""
                    onChange={handleThryvLeadIdChangeValue}
                    value={thryvLeadId}
                  />
                  {leadValidation.passed && (
                    <>
                      <Icon
                        className="ml-3"
                        type="solid"
                        variant="circleCheck"
                        color="#5cb85c"
                        height="30"
                        width="30"
                      />
                      <Icon
                        style={{cursor: 'pointer'}}
                        className="ml-3"
                        onClick={handleLeadEdit}
                        type="regular"
                        variant="edit"
                        color="#FF5000"
                        height="30"
                        width="30"
                      />
                    </>
                  )}
                  {!leadValidation.passed && (
                    <>
                      <Button
                        className="ml-3"
                        variant="primary"
                        onClick={validateThryvLeadId}
                      >
                        Validate
                      </Button>
                      <Icon
                        style={{cursor: 'pointer'}}
                        className="ml-3"
                        onClick={toggleThryvLeadsAddRemoveButton}
                        type="regular"
                        variant="circleX"
                        color="#FF5000"
                        height="30"
                        width="30"
                      />
                    </>
                  )}
                </>
              )}
              {item.name === 'Thryv Leads' &&
                !leadValidation.passed &&
                !showThryvLeadInput && (
                  <Icon
                    style={{cursor: 'pointer'}}
                    className="ml-3"
                    onClick={() => toggleThryvLeadsAddRemoveButton()}
                    type="solid"
                    variant="circlePlus"
                    color="#FF5000"
                    height="30"
                    width="30"
                    data-testid="add-remove-thryv-leads"
                  />
                )}
            </div>
            <div className="row ml-3 mb-4">
              {showProposalIdInput && (
                <>
                  <Input
                    className="w-[120px]"
                    type="text"
                    placeholder="Proposal ID"
                    name="budget"
                    withLabel
                    labelType="static"
                    variant="input-isolated"
                    symbol=""
                    onChange={handleProposalIdChangeValue}
                    value={proposalId}
                  />
                  {proposalValidation.isValidProposal && (
                    <>
                      <Icon
                        className="ml-3"
                        type="solid"
                        variant="circleCheck"
                        color="#5cb85c"
                        height="30"
                        width="30"
                      />
                      <Icon
                        style={{cursor: 'pointer'}}
                        className="ml-3"
                        onClick={handleProposalEdit}
                        type="regular"
                        variant="edit"
                        color="#FF5000"
                        height="30"
                        width="30"
                      />
                    </>
                  )}
                  {!proposalValidation.isValidProposal && (
                    <>
                      <Button
                        className="ml-3"
                        variant="primary"
                        onClick={validateProposalId}
                      >
                        Validate
                      </Button>
                      <Icon
                        style={{cursor: 'pointer'}}
                        className="ml-3"
                        onClick={toggleProposalIdAddRemoveButton}
                        type="regular"
                        variant="circleX"
                        color="#FF5000"
                        height="30"
                        width="30"
                      />
                    </>
                  )}
                </>
              )}
              {SEO_ADD_ONS.includes(item.name) &&
                !isSEOAlreadyInCart &&
                !proposalValidation.passed &&
                !showProposalIdInput && (
                  <>
                    <Icon
                      style={{cursor: 'pointer'}}
                      className="ml-3"
                      onClick={toggleProposalIdAddRemoveButton}
                      type="solid"
                      variant="circlePlus"
                      color="#FF5000"
                      height="30"
                      width="30"
                    />
                  </>
                )}
            </div>
          </>
        )}
        {showProposalIdInput && proposalValidation.isValidProposal && (
          <>
            <div className="row ml-3 mb-4">
              {['AUS', 'CAN', 'USA'].includes(selectedCountry) && (
                <Select
                  options={SEO_MBG_PRICES[selectedCountry]}
                  selectLabel="SEO MBG Price"
                  width="md"
                  selectedOption={selectedSeoMbgPrice}
                  setSelectedOption={setSelectedSeoMbgPrice}
                />
              )}
              <Icon
                style={{cursor: 'pointer'}}
                className="ml-3"
                onClick={() => toggleProposalIdAddRemoveButton()}
                type="regular"
                variant="circleX"
                color="#FF5000"
                height="30"
                width="30"
              />
            </div>
            {showSeoSwitchElement && (
              <div className="row ml-3 mb-4">
                <Checkbox
                  hasLabel
                  name="HTML"
                  isChecked={isChecked}
                  onChange={handleChangeSeo}
                  label="Switch to Standard SEO (Remove Money Back Guarantee)"
                />
              </div>
            )}
          </>
        )}
        {leadValidation.passed && (
          <div>
            <div className="row ml-3 mb-4">
              Do you want to start the billing on a different date?
            </div>
            <div className="row ml-3 mb-4">
              <AddOnsDatePickerModal
                itemName={item.name}
                setSelectedDate={setThryvLeadBillingDate}
                billingDate={thryvLeadBillingDate}
              />
            </div>
          </div>
        )}
        {proposalValidation.isValidProposal && (
          <div>
            <div className="row ml-3 mb-4">
              Do you want to start the billing on a different date?
            </div>
            <div className="row ml-3 mb-4">
              <AddOnsDatePickerModal
                itemName={item.name}
                setSelectedDate={setSeoBillingDate}
                billingDate={seoBillingDate}
              />
            </div>
          </div>
        )}
      </AddOnItem>
    </>
  )
}

export {ThryvValidatedAddons}
