import {Button, ParagraphText} from '@thryvlabs/maverick'
import dayjs from 'dayjs'
import React from 'react'
import {DateTimeModal} from '../date-time-modal'
import {Spinner} from '../loader-spinner'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {useUserRoles} from '../../utils/use-user-roles'

const RADIO_LANGUAGE_PREFERENCES = [
  {name: 'Yes', value: true},
  {name: 'No', value: false},
]

export default function UnconfirmedCall({
  apptData,
  selectedCentersAndApps,
  isLoading,
  shouldEnableCreativeCallModal,
  includeAddonsCodes,
  accountInformation,
  isRestrictedAccessHIPPASelected,
  noneCustomVideoCreativeItem,
  selectedService,
  isMCSelected,
  isUsSpanishOptionAvailable,
  register,
  errors,
  apptId,
  defaultDate = () => new Date(),
  isThryvLeadsOrSeoIncluded,
  onboardingCallDate,
  isThryvLeads = false, // new field inclusion for tl mc
  isNewTLMCAccessible,
  keapAccount,
}) {
  const {frontendSkipAppointment} = useFlags()
  const {roles} = useUserRoles()

  const roleAuth = roles.includes('Admin')

  const formatDate = dateStr => {
    return dayjs(dateStr.ISODate)
      .tz(dateStr.timeZoneId)
      .format('dddd, MMMM D, YYYY [at] h:mm A')
  }

  const renderCallDateSection = () => {
    if (apptData.callDate) {
      return (
        <ParagraphText variant="reg" color="thryv-gray-medium-500">
          Your{' '}
          {apptData.callType === 'kickoff'
            ? 'Thryv Kickoff Call'
            : 'creative consulation'}{' '}
          is scheduled for{' '}
          <ParagraphText
            variant="reg"
            color="thryv-black-500"
            className="inline-block"
          >
            <b>
              {formatDate(apptData.callDate)}{' '}
              {dayjs().tz(apptData.callDate.timeZoneId).format('z')}.
            </b>
          </ParagraphText>{' '}
          Please Confirm.
        </ParagraphText>
      )
    } else if (!selectedCentersAndApps) {
      return (
        <>
          <ParagraphText variant="reg" color="thryv-gray-medium-500">
            Schedule your{' '}
            {apptData.callType === 'kickoff'
              ? 'Thryv Kickoff Call'
              : 'creative consultation'}
          </ParagraphText>
          {apptData.displayKickOffCall && (
            <ParagraphText variant="reg" color="thryv-gray-medium-500">
              (Creative Consultations must be scheduled at least one day after
              Thryv Kickoff Call)
            </ParagraphText>
          )}
        </>
      )
    }
    return null
  }

  const renderErrorSection = () => {
    const callVarText =
      apptData.callType === 'creative'
        ? 'creativeCallDate'
        : 'onboardingCallDate'
    if (errors?.[callVarText]?.message) {
      return (
        <ParagraphText variant="reg" color="utility-error-danger">
          {errors.creativeCallDate.message}
        </ParagraphText>
      )
    }
    return null
  }

  const renderButtonSection = () => {
    let alphaSEOAdded = selectedCentersAndApps?.centers.find(
      ({name}) => name === 'SEO',
    )
    let isNewSEO = false
    if (alphaSEOAdded) {
      if (!(alphaSEOAdded?.alreadyIncluded === true)) isNewSEO = true
    }

    if (!isNewSEO && !selectedCentersAndApps) {
      let codes = includeAddonsCodes(
        `plans/${
          apptData.callType === 'kickoff' ? 'onboarding' : 'creative'
        }/available-times?country=${accountInformation.country}&keapAccount=${
          keapAccount || false
        }&address=${encodeURIComponent(accountInformation.address)}%20${
          accountInformation.city
        }%20${accountInformation.state}&software=${
          apptData.industryCategoryType
        }`,
        apptData.callType,
      )

      if (
        codes.split(',').find(item => item === 'SEO') ||
        codes.split('=').find(item => item === 'SEO')
      ) {
        isNewSEO = true
      }
    }
    // tl mc new logic code
    let isNewThryvLeads = isThryvLeads && isNewTLMCAccessible
    console.log({isNewThryvLeads})
    //
    if (apptData.callDate) {
      return (
        <>
          <Button
            variant="secondary"
            buttonType="outlined"
            onClick={apptData.resetAppointment}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="ml-2"
            onClick={apptData.confirmAppointment}
            disabled={isLoading}
          >
            Confirm
          </Button>
        </>
      )
    } else if (
      apptData.callType !== 'creative' ||
      shouldEnableCreativeCallModal()
    ) {
      return (
        <div
          style={{
            justifyContent: selectedCentersAndApps ? 'flex-end' : 'block',
            display: 'flex',
            marginTop: selectedCentersAndApps ? '15px' : '0px',
          }}
        >
          {selectedCentersAndApps &&
            frontendSkipAppointment &&
            (!isNewThryvLeads || roleAuth) && (
              <Button
                onClick={() => {
                  apptData.skipAppointment(apptData.callType)
                }}
                variant="text"
                level={1}
                textVariant="light"
                className="mr-3"
              >
                Skip
              </Button>
            )}
          <DateTimeModal
            modalTitle={`Schedule your ${
              apptData.callType === 'creative'
                ? 'Creative Call'
                : 'Thryv Kickoff Call'
            }`}
            buttonText="schedule"
            modalId={`${
              apptData.callType === 'creative' ? 'creative' : 'onboarding'
            }-call-modal`}
            callType={apptData.callType}
            saveDateTime={apptData.saveCallData}
            register={register}
            errors={errors}
            address="Chicago"
            customURL={includeAddonsCodes(
              selectedCentersAndApps
                ? `cc/order/${
                    apptData.callType === 'kickoff' ? 'onboarding' : 'creative'
                  }/available-times?country=${
                    accountInformation.country
                  }&keapAccount=${
                    keapAccount || false
                  }&address=${encodeURIComponent(
                    accountInformation.address,
                  )}%20${accountInformation.city}%20${
                    accountInformation.state
                  }&languagePreference=${apptData.getLanguagePreference(
                    apptData.callType,
                  )}`
                : `plans/${
                    apptData.callType === 'kickoff' ? 'onboarding' : 'creative'
                  }/available-times?country=${
                    accountInformation.country
                  }&keapAccount=${
                    keapAccount || false
                  }&address=${encodeURIComponent(
                    accountInformation.address,
                  )}%20${accountInformation.city}%20${
                    accountInformation.state
                  }&software=${apptData.industryCategoryType}`,
              apptData.callType,
            )}
            defaultDate={defaultDate()}
            isCreativeCall={apptData.callType === 'creative'}
            isMCSelected={isMCSelected}
            radioLanguageOptions={RADIO_LANGUAGE_PREFERENCES}
            setLanguagePreference={apptData.setLanguagePreference}
            country={accountInformation.country}
            isV5={selectedCentersAndApps ? true : false}
            isRestrictedAccessHIPPASelected={isRestrictedAccessHIPPASelected}
            noneCustomVideoCreativeItem={noneCustomVideoCreativeItem}
            customServiceId={selectedService?.value}
            isUsSpanishOptionAvailable={isUsSpanishOptionAvailable}
            isKickoff={apptData.callType === 'kickoff'}
            isThryvLeadsOrSeoIncluded={isThryvLeadsOrSeoIncluded}
            is5Order={!!selectedCentersAndApps}
            disabled={Object.keys(errors).length > 0}
            onboardingCallDate={onboardingCallDate}
            isNewSEO={isNewSEO}
          />
        </div>
      )
    } else {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            marginTop: '10px',
          }}
        >
          <Button
            className="mt-2"
            disabled={true}
            variant="primary"
            style={{height: 33.6}}
          >
            Schedule
          </Button>
        </div>
      )
    }
  }

  const renderSpinnerSection = () => {
    if (
      (apptData.callType === 'kickoff' &&
        isLoading &&
        apptId !== null &&
        apptData.callDate) ||
      (isLoading && !apptData.displayKickOffCall && apptData.callDate)
    ) {
      return <Spinner />
    } else if (
      (apptData.callType === 'creative' &&
        isLoading &&
        apptId !== null &&
        apptData.callDate) ||
      (isLoading && !apptData.displayCreativeCall && apptData.callDate)
    ) {
      return <Spinner />
    }
    return null
  }

  return (
    <>
      {renderCallDateSection()}
      {renderErrorSection()}
      {renderButtonSection()}
      {renderSpinnerSection()}
    </>
  )
}
